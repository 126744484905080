<template>
  <div>
    <detail-employee
      :employee="singleEmployee"
      :programmingLanguage="programmingLanguage"
      :framework="framework"
      :database="database"
      :platform="platform"
      :developmentTools="developmentTools"
      :operatingSystem="operatingSystem"
    />
    <b-card>
      <h6 class="mb-4 font-weight-bold">Filter</h6>
        <b-row align-v="center">
          <b-col>
              <b-form-group
                label="Status"
              >
                <custom-select
                  v-model="filter"
                  :options="employeeFilter.filters"
                  multiple
                  :clearable="false"
                  @change="$refs.table.refresh()"
                  placeholder="Select status"
                />
              </b-form-group>
          </b-col>
          <b-col>
            <div class="button-project mt-2 d-flex justify-content-end">
              <b-button
                class="reset mr-3"
                variant="outline-warning"
                @click="resetFilter"
              >
                Reset Filter
              </b-button>
              <b-button
                class="next"
                variant="warning"
                @click="applyFilter"
              >
                Apply Filter
              </b-button>
            </div>
          </b-col>
      </b-row>
    </b-card>
    <br>
    <b-card>
      <div class="row mb-2 mt-2">
        <div class="col-lg-6 d-flex justify-content-start">
          <b-button
            class="next merge mt-6 ml-50"
            variant="warning"
            @click="goToAddEmployee"
            >
              Add Employee
          </b-button>
        </div>
      </div>
      <b-row v-show="!employee.length">
        <b-col>
          <div align="center">
            <img src="@/assets/icon-no-invoice.png"/>
          </div>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        class="mt-2"
        v-show="employee.length"
        :items="fetchListEmployee"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        sort-icon-left
        striped
        responsive
      >
        <template #cell(employee-number)="{ employee }">
          {{ employee.employeeNo }}
        </template>
        <template #cell(full-name)="{ employee }">
          {{ employee.fullName }}
        </template>
        <template #cell(status)="{ item, index }">
          <b-form-checkbox
            v-model="employee[index].status"
            name="checkbox-button"
            switch
            @change="(val) => handleEditStatus(item.id, val)"
          >
            {{ employee[index].status ? 'Active' : 'Inactive' }}
          </b-form-checkbox>
        </template>
        <template #cell(phone-number)="{ employee }">
          {{ employee.phoneNumber }}
        </template>
        <template #cell(email-address)="{ employee }">
          {{ employee.email }}
        </template>
        <template #cell(log-employees)="{ employee }">
          <p>Last update: {{ employee.log }}</p>
        </template>
        <template #cell(action)="{ item }">
          <div class="data" >
            <b-link
              class="text-secondary mx-2"
              @click="editEmployee(item.id)"
            >
              <fa-icon icon="edit"/>
            </b-link>
            <b-link
              class="text-secondary mx-2"
            >
              <fa-icon icon="eye" @click="viewEmployee(item.id)"/>
            </b-link>
          </div>
        </template>
      </b-table>
      <div class="d-flex justify-content-between">
          <div>
            <span>Show</span>
            <b-form-select
              v-model="perPage"
              :options="pageOptions"
              @change="$refs.table.refresh()"
              class="page-option ml-2 mr-2 w-auto"
              size="sm"
            />
            <span>Entries</span>
          </div>
          <div>
            <b-pagination
              class="mb-0"
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              pills
              hide-goto-end-buttons
              @input="$refs.table.refresh()"
            />
            <span class="show-entries mr-auto">
              {{
              `Show ${
                totalRows === 0 ? 0 : (currentPage - 1) * perPage + 1
              } to ${
                totalRows > currentPage * perPage
                  ? currentPage * perPage
                  : totalRows
              } of ${totalRows} entries`
            }}
            </span>
          </div>
        </div>
      </b-card>
  </div>
</template>

<script>
import api from '@/api'
import detailEmployee from '@/views/menus/employee/ModalDetailEmployee.vue'

export default {
  components: { detailEmployee },
  props: {
    status: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    sortBy: 'fullName',
    employee: [
      {
        id: '',
        employeeNo: '',
        fullName: '',
        status: '',
        phoneNumber: '',
        email: '',
        log: ''
      }
    ],
    singleEmployee: {
      employeeNo: '',
      fullName: '',
      nickName: '',
      identityCard: '',
      status: 'active',
      gender: '',
      birthPlace: '',
      npwp: '',
      birthDate: '2000-01-01',
      email: '',
      religionId: '',
      provinceId: '',
      cityId: '',
      districtId: '',
      phoneNumber: '',
      address: '',
      postalCode: '',
      vaccineNumber: '',
      objective: '',
      profile: '',
      other: '',
      emergencyContactName: '',
      emergencyContactNumber: '',
      socialMedia: [],
      language: [],
      knowledge: {},
      currentSalary: 0,
      hireDate: '',
      resignDate: null,
      position: '',
      bankAccNumber: '',
      bankId: '',
      role: ''
    },
    programmingLanguage: [],
    framework: [],
    database: [],
    platform: [],
    developmentTools: [],
    operatingSystem: [],
    filter: ['Active'],
    employeeFilter: {
      show: true,
      filters: ['Active', 'Inactive']
    },
    candidateIdView: null,
    candidateIdEdit: null,
    currentPage: 1,
    perPage: 10,
    totalRows: 0,
    totalPage: 0,
    fields: [
      { key: 'employeeNo', label: 'Id', sortable: true },
      { key: 'fullName', label: 'Full Name', tdClass: 'text-center', thClass: 'text-center', sortable: true },
      { key: 'status', label: 'Status', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'phoneNumber', label: 'Phone Number', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'email', label: 'Email Address', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'log', label: 'Log', tdClass: 'text-center', thClass: 'text-center' },
      { key: 'action', label: 'Action', tdClass: 'text-center', thClass: 'text-center' }
    ],
    pageOptions: [10, 20, 50, 100],
    items: []
  }),
  methods: {
    async handleEditStatus(id, val) {
      await api.employee.changeStatus(id, { status: val })
      this.fetchListEmployee()
    },
    async fetchListEmployee() {
      let paramStatus = ''
      if (this.filter.length === 1) {
        paramStatus = this.filter[0] === 'Active' ? 'True' : 'False'
      } else {
        paramStatus += 'True|False'
      }

      const { data } = await api.employee.list({
        page: this.currentPage,
        pageSize: this.perPage,
        Filters: `Status==${paramStatus}`,
        sorts: this.sortBy
      })

      this.employee = data.length !== 0 ? data.data : []
      this.totalRows = data.length !== 0 ? data.totalData : 0
      this.totalPage = data.totalPage
      data.data.map((v, i) => {
        if (i % 2 === 0) {
          v._rowVariant = 'warning'
        }
      })
      this.employee = data.data
      return this.employee
    },
    async viewEmployee(id) {
      const { data } = await api.employee.get(id)
      this.singleEmployee = data.data
      this.programmingLanguage = this.singleEmployee.knowledge.programmingLanguage
      this.framework = this.singleEmployee.knowledge.framework
      this.database = this.singleEmployee.knowledge.database
      this.platform = this.singleEmployee.knowledge.platform
      this.developmentTools = this.singleEmployee.knowledge.developmentTools
      this.operatingSystem = this.singleEmployee.knowledge.operatingSystem
      this.$bvModal.show('detail-employee')
    },
    async editEmployee(id) {
      this.candidateIdEdit = id
      this.$router.push({
        path: `/employee/${id}`,
        query: {
          id: id
        }
      })
    },
    goToAddEmployee() {
      this.$router.push('/employee/add-employee')
    },
    applyFilter() {
      this.$refs.table.refresh()
    },
    resetFilter() {
      this.filter = this.$options.data().filter
      this.$refs.table.refresh()
    },
    async filterList() {
      const { data } = await api.employee.listFilter({
        Filters: [`${this.filter ? `Active==${this.filter}` : ''}`]
      })
      this.filter = data
    }
  }
}
</script>
